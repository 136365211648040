/* VERSION DESIGN - Non Material UI Styles for APP build */

/* General styles
---------------------------------------------------------------------------------------------- */

/* Font Sizes - converted from px to rem
----------------------------------------------------------------------------------------------
8px		- 	0.5rem
9px		- 	0.563rem
10px		- 	0.625rem
11px		- 	0.688rem
12px		- 	0.75rem
13px		-	0.813rem
14px		-	0.875rem
15px		-	0.938rem
16px		-	1rem
17px		-	1.063rem
18px		-	1.125rem
19px		-	1.188rem
20px		-	1.25rem
---------------------------------------------------------------------------------------------- */

/* Font weights
----------------------------------------------------------------------------------------------
200 - Extra-light
300 - Light
400 - Regular
600 - Semi-Bold
700 - Bold
800 - Extra-bold
900 - Black
---------------------------------------------------------------------------------------------- */


/* Base Styles */

html {
	height: 100%;
}

body {
	height: calc(100% - 80px);
}

* {
	margin: 0;
	padding: 0;
	outline: 0;
}

body {
	min-height: 700px;
	margin-bottom: 70px;
	clear: both;
	background-color: #ffffff;
	font-family: 'Roboto', sans-serif;
	font-weight: normal;
	font-style: normal;
	/* border-bottom: 80px solid #6D7C86; */
}

body, h1, h2, h3, h4, h5, h6,  p, ul, li, form {
	margin: 0px;
	padding: 0px;
}

p {
	font-size: 0.875rem;
	line-height: 1.2rem;
}

input, select, button {
	font-weight: normal;
	font-style: normal;
}

input, select, button {
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box;         /* Opera/IE 8+ */
}


a:link, a:visited {
	outline: none;
}

a:hover, a:active {
	text-decoration: none;
	outline: none;
}

img {
	border: none;
	width: 100%;
	height: auto;
}

.bodyContainer {
	margin-top: 0px;
	max-width: 1520px;
}

.bodyContainer.no-header {
	margin-top: -159px;
}


/* Material UI Component overrides 
---------------------------------------------------------------------------------------------- */


/* Nuevopark Header
---------------------------------------------------------------------------------------------- */

.headerBackground {
	background-color: #2857a7;
	height: 192px;
	width: 100%;
}

.headerWrapper {
	min-height: 230px;
	margin-top: -192px;
}

.topHeader {
	display: flex;
	padding-top: 35px;
	padding-bottom: 15px;
}

.headerTitle {
	color: #fff;
	width: 50%;
}

.headerTitle h2 {
	font-size: 2.125rem;
	font-weight: 100;
}

.headerNavigationWrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: 50%;
}

.headerLogo {
	max-width: 305px;
}	

.headerNavigation {
	padding-top: 35px;
	padding-bottom: 0px;
}

.headerNavigation a {
	font-size: 1rem;
	font-weight: 500;
	color: #fff;
	padding-left: 12px;
	padding-right: 12px;
	text-decoration: none;
	border-right: 1px solid white;
}

.headerNavigation a:last-of-type {
	border-right: none;
	padding-right: 0px;
}

.bookingControls {
	background-color: white;
	min-height: 150px;
	padding: 20px 15px 15px 15px;
	border-radius: 6px;
	box-shadow: 0px 2px 12px rgba(0,0,0,0.2);
}

/* booking buttons */

.bookingControls .MuiFormControl-root {
	padding:0px; /* 5px */
}
.bookingControls.custom-input-fields .MuiFormControl-root {
	padding:0px;
}

.bookingControls .MuiStep-root {
	text-align: center;
	font-size: 9px;
	font-weight: 600;
	text-transform: uppercase;
	color: #009EFF;
}

.bookingControls .bookingButtons {
	display: flex;
	padding-top: 20px;
}

.bookingButtons .MuiOutlinedInput-root,
.manage-buttons .MuiOutlinedInput-root {
	border-radius: 4px;
}

.bookingButtons .MuiTypography-root,
.manage-buttons .MuiTypography-root {
	font-size: 14px;
	color: #000000;
}

/* This changes the style of the input typed text */

.MuiInputBase-inputAdornedStart,
#entry-time-input,
#exit-time-input {
	font-size: 1.125rem !important;
	color: #3F5665 !important;
}

.MuiOutlinedInput-input {
	font-size: 1.063rem !important;
	color: #3F5665 !important;
}

.MuiInputAdornment-positionEnd .MuiSvgIcon-root {
	color: #009EFF;
	font-size: 20px;
}

.bookingControls .destination-input {
	width: 364px; /* 372px */
	margin-right: 4px;
}

.bookingControls .entry-date-input {
	width: 390px; /* 252px; */
	margin-right: 4px;
}

.bookingControls .entry-time-input {
	width: 116px;
	margin-right: 4px;
}

.bookingControls .exit-date-input {
	width: 390px; /* width: 252px; */
	margin-right: 4px;
}

.bookingControls .exit-time-input {
	width: 116px;
	margin-right: 4px;
}

.bookingControls .promo-code-input {
	width: 250px;
	margin-right: 4px;
}

.bookingControls .searchButton-wrapper {
	width: 110px;
	display: flex;
	margin-right: 0 !important;
	padding-right: 0 !important;
}

.bookingControls .searchButton-wrapper .MuiFormControl-root,
.bookingControls .grid-element.search-input .MuiFormControl-root {
	width: 100% !important;
}

.bookingControls .grid-element .MuiFormControl-root {
	width: calc(100% - 14px) !important;
}

.search-input {
	display: flex;
}

.searchButtonControl {
	align-self: stretch;
}

.bookingControls .searchButton {
	background-color: #2857a7;
	font-size: 1rem;
	padding: 6px 12px;
	text-transform: capitalize;
	box-shadow: none;
	height: 100%;
}

.nextButtonControl .nextButton {
	background-color: #2857a7;
	font-size: 1rem;
	padding: 4px 32px;
	text-transform: uppercase;
	box-shadow: none;
}

.bookingControls .MuiFormControl-root {
	width: calc(100% - 10px) !important;
}

.next-button-wrapper {
	padding-top: 40px;
	text-align: right;
}

.bookingControls .bookingButtons.selected-carpark {
	padding-top: 57px;
}

.selected-carpark .selected-park-column {
	justify-content: flex-start;
}

.selected-carpark .selected-time-column {
	justify-content: center;
}

.selected-carpark .selected-offer-column {
	justify-content: flex-end;
}

.bookingButtons.selected-carpark .MuiGrid-item {
	display: flex;
	align-items: center;
}

.selected-carpark .selected-carpark-name,
.selected-carpark .selected-carpark-date-time,
.selected-carpark .selected-carpark-offer {
	font-size: 1.125rem;
	color: #3F5665;
}

.selected-carpark .selected-carpark-offer {
	margin-right: 10px;
}

.selected-carpark .selected-time {
	padding: 0 0 0 7px;
}

.selected-carpark .selected-hyphen {
	padding: 0 12px 0 12px;
}

.SelectedParkIcon {
	width: 18px;
	margin-right: 14px;
	margin-left: 10px;
}

.SelectedParkPinIcon {
	width: 15px;
	margin-left: 8px;
}

.SelectedParkCalendarIcon {
	width: 15px;
	margin-right: 12px;
}

.SelectedParkOfferIcon {
	width: 19px;
	margin-right: 12px;
	margin-top: -3px;
}

.bookingButtons .search-pin-icon {
	width: 14px;
}

.bookingButtons .search-calendar-icon {
	width: 13px;
}

.bookingButtons .search-time-icon {
	width: 16px;
}

.bookingButtons .search-promo-icon {
	width: 16px;
}

/* Custom two line inputs */

.two-line-input .custom-label {
	margin: 10px 0 0 15px !important;
	font-size: 0.75rem !important;
	transform: none !important;
	color: #888888;
	font-weight: 500;
}

.bookingButtons .two-line-input .custom-label {
	margin: 10px 0 0 32px !important;
}

.two-line-input .custom-label.optional-label:after {
	content: ' (optional)';
	color: #999;
}

.two-line-input .MuiOutlinedInput-input {
	padding: 30px 10px 10px 15px;
}

.bookingButtons .two-line-input .MuiOutlinedInput-input {
	padding: 30px 10px 10px 10px;
}

.two-line-input .MuiInputAdornment-root.MuiInputAdornment-positionStart {
	margin-top: 20px;
	margin-right: 0;
	padding-right: 0;
}

.bookingButtons .MuiOutlinedInput-root.MuiInputBase-root {
	padding-left: 10px;
}

.bookingButtons .MuiInputAdornment-positionStart.MuiInputAdornment-outlined {
	margin-top: -27px;
}


/* Nuevopark Footer  */

.sticky-footer {
	min-height: 80px;
	width: 100%;
	background-color: #6D7C86;
	position: fixed;
	bottom: 0;
}



/* Nuevopark Static Stepper 
---------------------------------------------------------------------------------------------- */

.nuevo-stepper {
	width: 100%;
}

.nuevo-stepper .stepper-text {
	display: flex;
	width: 100%;
	flex-direction: row;
}

.nuevo-stepper .stepper-icons {
	display: flex;
	width: 100%;
	flex-direction: row;
}

.nuevo-stepper .stepper-text {
	margin-bottom: 10px;
}

.nuevo-stepper .stepper-text .text-wrapper {
	width: 12%;
	text-align: center;
}

.nuevo-stepper .stepper-text .text-wrapper.step-one,
.nuevo-stepper .stepper-text .text-wrapper.step-two,
.nuevo-stepper .stepper-text .text-wrapper.step-three,
.nuevo-stepper .stepper-text .text-wrapper.step-four {
	margin-right: 10%;
}

.nuevo-stepper .stepper-text .text-wrapper.step-five {
	
}

.nuevo-stepper .stepper-text .text-wrapper .step-text {
	font-size: 0.75rem;
	color: #999999;
	font-weight: 500;
}

.nuevo-stepper .stepper-text .text-wrapper .step-text.active-step {
	color: #2857a7;
}

.nuevo-stepper .stepper-icons {
	height: 36px;
}

.stepper-progress-wrapper,
.stepper-icon-wrapper {
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.stepper-progress-wrapper.zero-one {
	width: 5%;
}

.stepper-progress-wrapper.one-two,
.stepper-progress-wrapper.two-three,
.stepper-progress-wrapper.three-four,
.stepper-progress-wrapper.four-five {
	width: 20%;
}

.stepper-progress-wrapper.five-six {
	width: 5%;
}

.stepper-icon-wrapper {
	height: 36px;
	width: 36px;
}

.stepper-progress-wrapper .progress-bar.active {
	width: 100%;
	height: 2px;
	background-color: #2857a7;
}

.stepper-progress-wrapper .progress-bar.inactive {
	width: 100%;
	height: 2px;
	background-color: #999999;
}

.step-circle {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.875rem;
}

.step-circle.inactive {
	border: 2px solid #999999;
	font-size: 0.875rem;
	color: #999999;
	font-weight: 500;
}

.step-circle.active {
	border: 2px solid #2857a7;
	font-size: 0.875rem;
	color: #ffffff;
	font-weight: 500;
	background-color: #2857a7;
}

.StepCompleteIcon {
	width: 14px;
}



/* Nuevopark Page Icons 
---------------------------------------------------------------------------------------------- */

.icon-wrapper {
	width: 100%;
	max-width: 1520px;
	margin: 0 auto;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.icon-wrapper .list-icon {
	width: 22px;
	margin: 0 10px 0 16px;
}

.icon-wrapper .map-icon {
	width: 22px;
	margin: 0 16px 0 10px;
}


/* Nuevopark Filters Component
---------------------------------------------------------------------------------------------- */

.cardbox {
	border-radius: 0px;
	box-shadow: 0px 0px 15px rgb(0,0,0,0.2);
}

.filters.cardbox { 
	border-radius: 6px;
	height: 100%;
	margin-top: 0px;
	display: flex;
	flex-direction: column;
}

.filterbox {
	padding: 20px 17px 20px 17px;
}

.filterheader {
	display: flex;
	flex-direction: row;
	padding-bottom: 10px;
	align-items: center;
	justify-content: space-between;
}

.filterfooter {
	padding: 20px 17px 20px 17px;
}

.filters .filterLabel {
	font-size: 0.875rem;
	font-weight: 500;
	color: #6D7C86;
	text-transform: none;
	width: 100%;
}

.filters .filterButtons {
	font-size: 0.813rem;
	color: #6D7C86;
	text-transform: none;
	letter-spacing: 0;
	width: 100%;
}

.filters .filterButtons .MuiSvgIcon-fontSizeSmall {
	font-size: 13px;
}

.filters.cardbox .filterTitle {
	font-size: 1.625rem;
	color: #2857a7;
}

.HeaderFilterIcon {
	width: 22px;
	margin-right: 12px;
}

.refresh-icon {
	display: block;
}

.HeaderRefreshIcon {
	width: 25px;
}

.spacer {
	display: block;
	width: 100%;
	height: 20px;
}

.MuiSlider-valueLabelLabel {
	font-size: 0.7rem;
	color: #6D7C86 !important;
}

.MuiSlider-valueLabel {
	top: 45px !important;
}

.filters.cardbox .MuiGrid-item.filterSlider {
	padding-bottom: 10px;
	padding-right: 20px;
}
.MuiSlider-colorPrimary {
	color: #009EFF !important;
}

.button-icon-disabled {
	width: 16px;
}

.button-icon-cctv {
	width: 18px;
}

.button-icon-gated {
	width: 21px;
}

.button-icon-covered {
	width: 20px;
}

.button-icon-evcharge {
	width: 21px;
}

.button-icon-moto {
	width: 22px;
}

.button-icon-carwash {
	width: 18px;
}

.button-icon-lift {
	width: 17px;
}

.button-icon-manned {
	width: 18px;
}

.button-icon-valet {
	width: 18px;
}

.filterfooter {
	margin-top: auto !important;
}

.anchored-button {
	
}


/* Home / Landing Page 
---------------------------------------------------------------------------------------------- */

.entryPageBG {
	margin-top: 48px;
}



/* Nuecard Component
---------------------------------------------------------------------------------------------- */

.nuecard.my-account {
	position: relative;
}

.nuecard.cardbox {
	margin-bottom: 15px;
	border-radius: 6px;
	box-shadow: 0px 0px 15px rgb(0,0,0,0.2);
	overflow: hidden;
	position: relative;
}

.nuecard.cardbox:last-child {
	margin-bottom: 0;
}

.offer-wrapper {
	margin-bottom: 15px;
	border-radius: 9px;
	border: 3px solid white;
}

.park-wrapper {
	margin-bottom: 15px;
	border-radius: 9px;
}

.offer-wrapper:last-child,
.park-wrapper:last-child {
	margin-bottom: 0px;
}


.offer-wrapper.selected,
.offer-wrapper:hover,
.offer-wrapper:active {
	border: 3px solid #d924c2;
}

.nuecard-header {
	background-color: #009EFF;
	padding: 10px 20px 10px 20px;
}

.nuecard-title,
.nueoffer-title,
.nueaddon-title {
	font-size: 1.25rem;
	color: #ffffff;
	padding-bottom: 5px;
}

.nuecard-id {
	font-size: 1.25rem;
	color: #ffffff;
}

.nuecard-address,
.nueoffer-subtitle,
.nueaddon-subtitle {
	font-size: 1.1rem;
	font-weight: 300;
	color: #ffffff;
	padding-bottom: 0px;
}

.nuecard-subtitle,
.nueoffer-details p {
	font-size: 0.938rem;
	color: #6D7C86;
	padding: 0px 0 0 0px;
}

.nuecard-image {
	display: flex;
	height: 100%;
	background-color: black;
	align-items: center;
	overflow: hidden;
}

.nuecard-image img {
	height: 100%;
	width: auto;
	max-width: 100%;
}

.open-map {
	width: 40px;
	height: 40px;
	background-color: white;
	border-radius: 6px;
	position: absolute;
	margin-top: 14px;
	margin-left: 0;
	right: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.nuecard.my-account .open-map {
	margin-left: 0;
	right: 14px;
}

.map-icon {
	display: inline-block;
}

.open-map-icon {
	width: 12px;
	margin-left: 7px;
}

a.favs-button {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 12px;
	top: 15px;
	background: rgba(255,255,255,0.4);
	padding: 2px;
	border-radius: 4px;
	width: 20px;
	height: 20px;
}

.park-wrapper a.favs-button .favs-icon {
	width: 17px;
	fill: white;
}

.park-wrapper.favourite a.favs-button .favs-icon,
.park-wrapper.favourite a.favs-button:active .favs-icon {
	fill: red !important;
}

.heart-icon {
	width: 20px;
}

.nuecard-content {
	padding: 20px 20px 10px 20px;
}

.nuecard-details {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background: #ffffff;
	padding: 10px 20px 0px 0px;
}

.nuecard-details .nuecard-details-item {
	font-size: 0.813rem;
	text-align: left;
	color: #3F5665;
}

.nuecard-details-item .item-icon-wrapper {
	display: flex;
	align-content: center;
	justify-content: center;
	width: 25px;
	height: 25px;
	background-color: #f1f1f1;
	border-radius: 4px;
	margin-right: 10px;
}

.nuecard-details .nuecard-details-item.price {

}

.nuecard-details .nuecard-details-item .MuiSvgIcon-root {
	font-size: 13px;
}

.nuecard-info {
	display: flex;
	padding: 10px 15px 12px 22px;
	align-items: center;
	margin: 0;
	background-color: #f1f1f1;
	/* border-top: 1px solid #f1f1f1; */
}

.nuecard.my-account .nuecard-info {
	padding: 17px 5px 17px 0px;
}

.nuecard-info .nuecard-info-item {
	color: #009EFF;
	font-size: 0.875rem;
	width: 25%;
	text-align: center;
	font-weight: 500;
}

.nuecard.my-account .nuecard-info .nuecard-info-item {
	letter-spacing: -0.5px;
}

.nuecard-info .nuecard-info-item .MuiSvgIcon-root {
	
}

.nuecard-details .nuecard-details-item,
.nuecard-info .nuecard-info-item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.nuecard-details .nuecard-details-item {
	padding: 0 0 7px 0;
	align-items: center;
}

.nuecard-button {
	padding: 16px;
}

.nuecard-details .walk-icon {
	width: 10px;
	margin-right: 0px; /* was 5px */
}

.nuecard-details .price-icon {
	width: 9px;
	margin-right: 0px; /* was 5px */
}

.nuecard-details .height-icon {
	width: 20px;
	margin-right: 0px; /* was 5px */
}

.nuecard-info .tariffs-icon {
	width: 18px;
	margin-right: 7px;
}

.nuecard-info .trading-hrs-icon {
	width: 18px;
	margin-right: 7px;
}

.nuecard-info .amenities-icon {
	width: 19px;
	margin-right: 7px;
}

.nuecard-delete {
	width: 20px;
	height: 20px;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	right: 10px;
	top: 35px;
}

.delete-park-icon {
	width: 12px;
}

.nuecard-info-item.last-item {
	justify-content: flex-end;
}


/* Selected Park Styles - Left Column */

.selection.selected-park.cardbox {
	border-radius: 6px;
	height: calc(100% - 20px);
	padding: 20px 0px 0px 0px;
	display: flex;
	flex-direction: column;
}

.full-height {
	height: 100%;
}

.selected-park {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.selected-park-header {
	padding: 0 17px;
}

.selected-park-title {
	font-size: 1.25rem;
	padding-bottom: 5px;
}

.selected-park-id {
	font-size: 1rem;
}

.selected-park-address {
	color: #6D7C86;
	font-size: 1rem;
	padding-bottom: 5px;
}

.selected-park-subtitle {
	color: #6D7C86;
	font-size: 0.875rem;
	padding-bottom: 10px;
}

.selected-park-image {
	display: flex;
}

.selected-park-details {
	display: flex;
	background: #f1f1f1;
	padding: 16px 0px;
}

.selected-park-details .selected-park-details-item {
	font-size: 0.75rem;
	width: 33%;
	text-align: center;
	color: #3F5665;
}

.selected-park-details .nselected-park-details-item.price {
	width: 40%;
}

.selected-park-details .selected-park-details-item .MuiSvgIcon-root {
	font-size: 13px;
}

.selected-park-info {
	display: flex;
	padding: 20px 10px 5px 0px;
	margin-bottom: 10px;
}

.selected-park.my-account .selected-park-info {
	padding: 17px 5px 17px 0px;
}

.selected-park-info .selected-park-info-item {
	color: #009EFF;
	font-size: 0.75rem;
	width: 33.33%;
	text-align: center;
	font-weight: 500;
}

.selected-park.my-account .selected-park-info .selected-park-info-item {
	letter-spacing: -0.5px;
}

.selected-park-info .selected-park-info-item .MuiSvgIcon-root {
	
}

.selected-park-details .selected-park-details-item,
.selected-park-info .selected-park-info-item {
	display: flex;
	align-items: center;
	justify-content: center;
}

.selected-park-details .walk-icon {
	width: 10px;
	margin-right: 5px;
}

.selected-park-details .price-icon {
	width: 9px;
	margin-right: 5px;
}

.selected-park-details .height-icon {
	width: 20px;
	margin-right: 5px;
}

.selected-park-info .tariffs-icon {
	width: 18px;
	margin-right: 7px;
}

.selected-park-info .trading-hrs-icon {
	width: 18px;
	margin-right: 7px;
}

.selected-park-info .amenities-icon {
	width: 19px;
	margin-right: 7px;
}

.selected-park-delete {
	width: 20px;
	height: 20px;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	right: 10px;
	top: 35px;
}

.selected-park-button {
	display: flex;
	margin: auto 17px 3px 17px
}




/* NueOffer card
---------------------------------------------------------------------------------------------- */

.nueoffer-price,
.nueaddon-price {
	font-size: 1.25rem;
	color: white;
	position: absolute;
	right: 20px;
	top: 10px;
}

.nueoffer-image {
	display: flex;
	height: 100%;
	align-items: center;
	overflow: hidden;
}

.nueoffer-image img {
	height: 100%;
	width: auto;
	max-width: 100%;
}

.nueoffer-details {
	padding: 20px 20px 10px 20px;
}

.nueoffer-info {
	display: flex;
	background-color: #f1f1f1;
	padding: 10px 0px 10px 0px;
	align-items: center;
	width: 100%;
}

.nueoffer-info .nueoffer-info-item {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.813rem;
	color: #3F5665;
	flex-grow: 1;
}

.nueoffer-info-item.last-item {
	justify-content: flex-end;
}

.nueoffer-details ul {
	font-size: 0.938rem;
	color: #6D7C86;
	padding-top: 10px;
	padding-left: 15px;
	margin-left: 10px;
	line-height: 1.5rem;
}

.nueoffer-info-item a,
.nueaddon-info-item a {
	text-decoration: none;
	color: #009EFF;
	font-size: 0.813rem;
	padding: 0;
	text-align: center;
}

.nueoffer-button {
	padding: 16px;
}

.nueoffer-info .cancel-icon {
	width: 12px;
	margin-right: 5px;
	margin-left: 10px;
}

.nueoffer-info .amend-icon {
	width: 12px;
	margin-right: 5px;
}

.nueoffer-info .extend-icon {
	width: 9px;
	margin-right: 5px;
}

.nueoffer-info .remove-icon {
	width: 14px;
	margin-left: 7px;
}

.nueoffer-info .add-icon {
	width: 18px;
	margin-left: 7px;
}



/* NueAddon card
---------------------------------------------------------------------------------------------- */

.nueaddon-right-col {
	display: flex;
	flex-direction: column !important;
}

.nueaddon-details {
	padding: 20px 20px 10px 20px;
	font-size: 0.938rem;
	line-height: 1.3rem;
	color: #6D7C86;
}

.nueaddon-info {
	display: flex;
	background-color: #f1f1f1;
	padding: 10px 0px 10px 0px;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	margin-top: auto
}

.nueaddon-info .nueaddon-info-item {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.813rem;
	color: #3F5665;
	flex-grow: 0;
	margin-left: 40px;
}

.nueaddon-info-item.last-item {
	justify-content: flex-end;
}


/* Selection box
---------------------------------------------------------------------------------------------- */

.selection.cardbox { 
	border-radius: 6px;
	height: calc(100% - 44px);
	padding: 17px 7px 27px 17px;
	display: flex;
	flex-direction: column;
}

.selection.cardbox .selectionTitle {
	font-size: 1.625rem;
	color: #2857a7;
}

.selection-title-row {
	padding-bottom: 10px;
}

.HeaderSelectionIcon {
	width: 25px;
	margin-right: 12px;
}

.selection-line-item-title {
	font-size: 1rem;
	color: #3F5665;
	padding: 7px 0;
}

.selection-line-item-price {
	text-align: right;
	font-size: 1rem;
	color: #3F5665;
}

.selection-line-item-total {
	font-size: 1rem;
	color: #3F5665;
}

.selection-line-item-total-price {
	text-align: right;
	font-size: 1rem;
	color: #3F5665;
}

.selectionbox.totals {
	margin-top: auto;
}

.EditSelectionIcon {
	width: 12px;
}




/* Address entry screen
---------------------------------------------------------------------------------------------- */

.address-wrapper {
	border-radius: 6px;
	padding: 17px 9px 1px 17px;
	box-shadow: 0px 0px 15px rgb(0,0,0,0.2);
	margin-bottom: 22px;
}

.address-wrapper:last-child {
	margin-bottom: 0;
}

.address-wrapper h2 {
	font-size: 1.625rem;
	color: #2857a7;
	font-weight: 400;
}

.address-wrapper .form-row,
.my-account-wrapper .form-row {
	display: flex;
	flex-direction:row;
	width: 100%;
	margin-bottom: 16px;
}

.address-wrapper .form-row .form-element-wrapper .MuiFormControl-root,
.my-account-wrapper .form-row .form-element-wrapper .MuiFormControl-root {
	width: calc(100% - 15px) !important;
}

.address-wrapper .form-row .form-element-wrapper .MuiFormControl-root:last-child,
.my-account-wrapper .form-row .form-element-wrapper .MuiFormControl-root:last-child {
	width: calc(100% - 10px) !important;
}

.address-wrapper .form-row .form-element-wrapper.align-right,
.my-account-wrapper .form-row .form-element-wrapper.align-right {
	text-align: right;
}

.address-wrapper .form-row .form-element-wrapper,
.my-account-wrapper .form-row .form-element-wrapper {
	width: 50%;
}

.address-wrapper .form-row .form-element-wrapper.first-name,
.address-wrapper .form-row .form-element-wrapper.last-name {
	width: 30%;
}

.address-wrapper .form-row .form-element-wrapper.business,
.address-wrapper .form-row .form-element-wrapper.mobile,
.address-wrapper .form-row .form-element-wrapper.lic-plate {
	width: 40%;
}

.address-wrapper .form-row .form-element-wrapper.email,
.address-wrapper .form-row .form-element-wrapper.vehicle-make {
	width: 60%;
}

.address-wrapper .form-row .form-element-wrapper.name-on-card {
	width: 42%;
}

.address-wrapper .form-row .form-element-wrapper.card-number {
	width: 33%;
}

.address-wrapper .form-row .form-element-wrapper.expiry {
	width: 14%;
}

.address-wrapper .form-row .form-element-wrapper.cvv {
	width: 11%;
}

.address-wrapper .MuiOutlinedInput-root {
	border-radius: 4px;
}

.address-wrapper .MuiTypography-root {
	color: #383838;
	font-size: 0.875rem;
}

.address-wrapper .form-row .form-element-wrapper .sign-in-link {
	text-decoration: none;
	color: #383838;
	font-size: 0.875rem;
	margin-right: 12px;
}

.address-wrapper .form-row .form-element-wrapper.credit-cards {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.address-wrapper .form-row .form-element-wrapper .ccard-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 42px;
	height: 26px;
	border-radius: 4px;
	border: 1px solid #ccc;
	margin-right: 5px;
	margin-left: 5px;
}

.address-wrapper .form-row .form-element-wrapper .ccard-wrapper.visa-icon .ccard-icon {
	width: 35px;
}

.address-wrapper .form-row .form-element-wrapper .ccard-wrapper.mcard-icon .ccard-icon {
	width: 30px;
}

.address-wrapper .form-row .form-element-wrapper .ccard-wrapper.amex-icon {
	background-color: #007AD9;
	margin-right: 10px;
}

.address-wrapper .form-row .form-element-wrapper .ccard-wrapper.amex-icon .ccard-icon {
	background-color: white;
	width: 42px;
}



/* Nuevopark Processing
---------------------------------------------------------------------------------------------- */

.processing-wrapper {
	border-radius: 6px;
	padding: 17px;
	box-shadow: 0px 0px 15px rgb(0,0,0,0.2);
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 400px;
}

.processing-wrapper h3 {
	font-size: 1.25rem;
	color: #383838;
	padding-bottom: 10px;
	font-weight: 400;
}

.processing-wrapper p {
	font-size: 1rem;
	color: #868484;
}

.ProcessingWheel {
	width: 75px;
	margin-bottom: 40px;
}



/* Nuevopark Confirmation
---------------------------------------------------------------------------------------------- */

.confirmation-wrapper {
	border-radius: 6px;
	padding: 27px 17px 17px 17px;
	box-shadow: 0px 0px 15px rgb(0,0,0,0.2);
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white;
	color: #3F5665;
}

.confirmation-inner {
	max-width: 684px;
}

.confirmation-wrapper .confirmation-inner p,
.confirmation-wrapper .confirmation-inner .MuiGrid-item {
	margin: 0;
	padding: 0;
}

.confirmation-wrapper .confirmation-order-title {
	margin-bottom: 20px;
}

.confirmation-wrapper .confirmationTitle {
	font-size: 1.625rem;
	color: #2857a7;
}

.HeaderConfirmationIcon {
	width: 25px;
	margin-right: 12px;
}

.confirmation-order-header {
	border-top: 1px solid #C9C9C9;
	border-bottom: 1px solid #C9C9C9;
	margin: 10px 0;
	padding: 10px 0;
}

.confirmation-order-body {
	margin: 10px 0;
	padding: 5px 0;
}

.confirmation-wrapper .confirmation-inner .confirmation-order-body .MuiGrid-item {
	padding: 10px 0;
}

.confirmation-order-footer {
	border-top: 1px solid #C9C9C9;
	border-bottom: 1px solid #C9C9C9;
	margin: 10px 0;
	padding: 12px 0;
}

.confirmation-order-ccard {
	text-align: center;
	margin: 30px 0 0 0;
	padding: 30px 0;
}

.confirmation-order-date {
	padding-bottom: 15px !important;
}

.confirmation-order-number,
.confirmation-order-price,
.confirmation-order-total-price {
	text-align: right;
}

.confirmation-order-car-park,
.confirmation-order-date-time,
.confirmation-order-vehicle {
	padding-bottom: 5px !important;
}

.edit-booking .MuiGrid-item {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.edit-booking .MuiGrid-item .amend-button {
	display: flex;
	height: 48px;
	text-align: right;
	color: #5FD12B;
	font-size: 1.063rem;
	font-weight: bold;
	align-items: center;
	justify-content: flex-end;
	margin-right: 30px;
}

.edit-booking .MuiGrid-item .cancel-button {
	display: flex;
	height: 48px;
	text-align: right;
	color: #FE2C2C;
	font-size: 1.063rem;
	font-weight: bold;
	align-items: center;
	justify-content: flex-end;
}

.amend-button .amend-icon {
	width: 24px;
	margin-right: 7px;
}

.cancel-button .cancel-icon {
	width: 25px;
	margin-right: 7px;
}


/* Manage Booking
---------------------------------------------------------------------------------------------- */

h1.manage-booking {
	color: #2857a7;
	font-size: 1.625rem;
	font-weight: 400;
	padding-bottom: 10px;
}

.manage-buttons {
	display: flex;
	padding-top: 5px;
}

.manage-buttons .booking-ref-input,
.manage-buttons .first-name-input,
.manage-buttons .last-name-input,
.manage-buttons .mobile-input {
	width: 23%;
}

.manage-buttons .search-input {
	width: 8%;
}

.booking-search {
	display: flex;
	padding-top: 5px;
	justify-content: flex-end;
}

.bookingControls .booking-search .searchButton {
	background-color: #2857a7;
	font-size: 1rem;
	text-transform: uppercase;
	padding: 4px 12px;
	box-shadow: none;
}


/* Sign in Modal
---------------------------------------------------------------------------------------------- */

.sign-in-modal {
	position: absolute;
	top: 50%;
	right: 50%;
	margin-right: -372px;
	background-color: white;
	display: flex;
	border-radius: 6px;
	max-width: 720px;
	padding: 24px;
	box-shadow: 0px 0px 20px rgba(0,0,0,0.5);
	z-index: 999999;
}

.sign-in-modal h2 {
	color: #2857a7;
	font-size: 1.625rem;
	font-weight: 400;
	display: flex;
	align-content: center;
	padding-bottom: 32px;
}

.sign-in-modal h2 .sign-in-icon {
	width: 26px;
	margin-right: 7px;
}

.sign-in-modal .modal-input {
	width: 100%;
	padding-bottom: 10px;
}

.sign-in-modal .MuiOutlinedInput-root {
	border-radius: 4px;
}

.sign-in-modal .MuiTypography-root {
	font-size: 14px;
	color: #000000;
}

.signin-button-wrapper {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-top: 10px;
}

a.forgot-password {
	display: flex;
	font-size: 1rem;
	color: #009EFF;
	margin-right: 16px;
}

.signin-button-wrapper .signinButton {
	background-color: #2857a7;
	font-size: 1rem;
	text-transform: uppercase;
	padding: 4px 22px;
	box-shadow: none;
}

.show-password-icon {
	width: 16px;
}

.css-1in441m span {
	display: none;
}

.modal-grid .MuiGrid-item {
	padding-top: 15px !important;
}


/* Nuevopark Create an account
---------------------------------------------------------------------------------------------- */

.create-account-wrapper {
	border-radius: 6px;
	padding: 17px 17px 17px 17px;
	box-shadow: 0px 0px 15px rgb(0,0,0,0.2);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background-color: white;
	color: #3F5665;
}

.create-account-inner {
	max-width: 684px;
}

.create-account-wrapper .create-account-inner p,
.create-account-wrapper .create-account-inner .MuiGrid-item {
	margin: 0;
	padding: 0;
}

.create-account-wrapper .create-account-title {
	margin-bottom: 20px;
	margin-left: 0 !important;
}

.create-account-wrapper .create-account-title {
	font-weight: 400;
	font-size: 1.625rem;
	color: #2857a7;
}

p.terms-conditions-text {
	font-size: 1rem;
}

a.terms-link {
	color: #009EFF;
}

.account-button-wrapper {
	padding-top: 40px;
	text-align: right;
}

.accountButtonControl .createAccountButton {
	background-color: #2857a7;
	font-size: 1rem;
	padding: 6px 12px;
	text-transform: uppercase;
	box-shadow: none;
}

.form-row.terms-conditions-wrapper {
	padding: 20px 0 40px 0;
}

.terms-checkbox-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.terms-checkbox-wrapper .MuiFormControlLabel-root {
	margin-right: 0 !important;
}


/* My Account Section - Added 100523
---------------------------------------------------------------------------------------------- */

.my-account-wrapper {
	border-radius: 6px;
	padding: 30px 17px 30px 30px;
	box-shadow: 0px 0px 15px rgb(0,0,0,0.2);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background-color: white;
	height: 480px;
}

.flex-height-wrapper {
	position: relative;
	flex: 1;
}

.bordered-column {
	padding-left: 24px !important;
	margin-left: 24px !important;
	border-left: 1px solid #D9DEE2;
	max-width: calc(75% - 30px) !important;
	height: 100%;
}

.flex-button-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.MyAccContainer {
	margin-top: -111px;
}

.myacccard.cardbox {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 75px 35px;
	background-color: white;
}

.myacccard-icon {
	height: 130px;
	margin-bottom: 80px;
}

.card-lrg-icon img {
	height: 129px;
}

.myacccard-header {
	height: 100px;
}

.myacccard-title {
	font-size: 1.875rem;
	color: #383838;
	text-align: center;
	padding-bottom: 15px;
}

.myacccard-subtitle {
	font-size: 1rem;
	color: #383838;
	font-weight: 300;
	text-align: center;
}

.myacccard-link {
	margin-top: 50px;
}

.card-arrow-icon {
	height: 24px;
}

h2.my-account-header {
	font-size: 1.625rem;
	margin-bottom: 10px;
	color: #009EFF;
	font-weight: 400;
}

h3.my-account-sub-header {
	font-size: 1.25rem;
	color: #009EFF;
	font-weight: 400;
	padding-bottom: 10px;
	margin-top: 8px;
}

h3.my-activity-sub-header {
	font-size: 1.25rem;
	color: #009EFF;
	font-weight: 400;
	border-bottom: 1px solid #D9DEE2;
	padding-bottom: 10px;
	margin-top: 8px;
}

.my-account-header-icon {
	width: 22px;
	margin-right: 12px;
}

.active-park-item {
	display: flex;
	flex-direction: row;
	font-size: 1rem;
	color: #383838;
	border-bottom: 1px solid #D9DEE2;
	padding: 15px 0;
}

.my-activity-menu,
.my-account-menu {
	border-top: 1px solid #D9DEE2;
	list-style: none;
	font-size: 1rem;
	color: #3F5665;
}

.my-activity-menu li,
.my-account-menu li {
	border-bottom: 1px solid #D9DEE2;
	padding: 16px 0;
	display: flex;
	flex-direction: row;
}

.my-activity-menu a,
.my-account-menu a {
	text-decoration: none;
	color: #3F5665;
	width: calc(100% - 37px);
	background-image: url('assets/svg/my-acc-arrow-sml.svg');
	background-repeat: no-repeat;
	background-position: 99.5% center;
}

.my-activity-menu a:hover,
.my-activity-menu a:active,
.my-activity-menu a.current,
.my-account-menu a:hover,
.my-account-menu a:active,
.my-account-menu a.current {
	background-image: url('assets/svg/my-acc-arrow-on-sml.svg');
}

.active-parks-menu-icon {
	width: 14px;
	margin: 0 15px 0 4px;
}

.bookings-menu-icon {
	width: 14px;
	margin: 0 15px 0 4px;
}

.leases-menu-icon {
	width: 16px;
	margin: 0 13px 0 4px;
}

.entitlements-menu-icon {
	width: 13px;
	margin: 0 16px 0 4px;
}

.history-menu-icon {
	width: 14px;
	margin: 0 15px 0 4px;
}

/* My Account menu icons */

.profile-menu-icon {
	width: 14px;
	margin: 0 14px 0 6px;
}

.vehicles-menu-icon {
	width: 19px;
	margin: 0 11px 0 4px;
}

.cards-menu-icon {
	width: 17px;
	margin: 0 13px 0 4px;
}

.car-parks-menu-icon {
	width: 14px;
	margin: 0 14px 0 6px;
}

.subscriptions-menu-icon {
	width: 17px;
	margin: 0 13px 0 4px;
}

.settings-menu-icon {
	width: 16px;
	margin: 0 14px 0 4px;
}

.security-menu-icon {
	width: 13px;
	margin: 0 15px 0 6px;
}

.forget-me-menu-icon {
	width: 14px;
	margin: 0 14px 0 6px;
}

.active-park-booking {
	
}

.active-park-divider {
	margin: 0 10px;
}

.active-park-status {
	width: 200px;
	margin-left: auto;
	text-align: left;
	background-image: url('assets/svg/my-acc-arrow-sml.svg');
	background-repeat: no-repeat;
	background-position: 93% center;
}

/* My Vehicles styles */

.sub-header-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.add-vehicle,
.add-card {
	display: flex;
	margin-left: auto;
	color: #5CADFF;
	font-size: 1rem;
	text-decoration: none;
	margin-right: 16px;
}

.add-item-icon {
	width: 16px;
	padding: 0 0 0 8px;
}

.vehicle-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 1.25rem;
	color: #3F5665;
	border: 1px solid #88c6e1;
	border-radius: 4px;
	padding: 5px 15px 5px 17px;
	margin-bottom: 7px;
	box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
}

.vehicle-logo {
	width: 40px;
	height: 40px;
	margin-right: 95px;
}

.vehicle-make {
	width: 250px;
}

.vehicle-registration {
	
}

.vehicle-options,
.card-options {
	display: flex;
	margin-left: auto;
}

.vehicle-edit-icon,
.card-edit-icon {
	width: 13px;
	margin-right: 15px;
}

.vehicle-delete-icon,
.card-delete-icon {
	width: 13px;
	margin-left: 15px;
}

/* My Account Cards styles */

.card-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 1.25rem;
	color: #3F5665;
	border: 1px solid #88c6e1;
	border-radius: 4px;
	padding: 6px 15px 6px 8px;
	margin-bottom: 7px;
	box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
}

.card-logo {
	display: flex;
	width: 56px;
	height: 36px;
	border: 1px solid #BFBFBF;
	border-radius: 5px;
	justify-content: center;
	margin-right: 88px;
}

.card-logo .visa-logo {
	width: 47px;
}

.card-logo .mcard-logo {
	width: 40px;
}

.card-number {
	width: 250px;
}

/* My Account Settings styles */

.app-notifications-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	border-top: 1px solid #c9c9c9;
	border-bottom: 1px solid #c9c9c9;
	padding: 15px 20px 15px 0;
}

.switch-wrapper {
	margin-left: auto;
}

.app-notifications-wrapper p {
	font-size: 1rem;
	color: #383838;
}

/* My Account Secuity styles */

.warning-text-wrapper {
	padding: 5px 0 70px 0;
}

.warning-password-text {
	font-size: 1rem;
	color: #383838;
	letter-spacing: -0.2px;
}

.enter-password-text {
	font-size: 1rem;
	color: #3F5665;
	padding-bottom: 12px;
}

.warning-icon {
	width: 20px;
	margin-right: 5px;
}

.bottom-button-wrapper {
	display: flex;
	align-items: stretch;
	justify-content: flex-end;
	margin-top: auto;
}

.greyButtonControl {
	
}

.greyButtonControl .greyButton {
	background-color: #8D9DB1 !important;
	box-shadow: none;
	text-transform: none;
	font-size: 1rem;
	letter-spacing: 0;
	padding: 10px 20px;
	min-width: 96px;
}




/* Content
---------------------------------------------------------------------------------------------- */


/* Typography
---------------------------------------------------------------------------------------------- */

/* p {
	padding-bottom: 15px;
} */


h1, h2, h3, h4, h5, h6 {

}


/* Form elements and Input fields
---------------------------------------------------------------------------------------------- */

/* .form-wrapper {
	width: calc(100% - 34px);
	background-color: #F7F7F7;
	border: 2px solid #f0f2f5;
	border-radius: 6px;
	box-shadow: 0px 2px 2px rgba(0,0,0,0.22);
	padding: 15px;
	float: left;
} */


/* Buttons
---------------------------------------------------------------------------------------------- */
/*

button {
	width: 100%;
	float: left;
	background-color: white;
	color: #607C96;
	font-size: 1rem;
	font-weight: 400;
	text-align: center;
	border-radius: 6px;
	border: 2px solid;
	border-color: #bfcad5;
}
*/


/* Footer
---------------------------------------------------------------------------------------------- */


/* Media Queries
---------------------------------------------------------------------------------------------- */

/* Desktop */

@media only screen and (min-width: 1000px) {
	
	
}

@media only screen and (max-width: 1520px) {
	
	.icon-wrapper {
		max-width: calc(100% - 40px);
		margin: 0 20px;
	}
}

/* Everything larger than Mobile */

@media only screen and (min-width: 501px) {

	
}